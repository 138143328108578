<template>
  <div class="seckillDatil" v-if="showPage">
    <van-nav-bar
        title="秒杀详情"
        fixed
        left-text=""
        left-arrow
        @click-left="$goBack()"
        @touchmove.prevent
      />
    <div class="content" @touchmove.stop >
      <van-swipe  >
        <van-swipe-item class="my-swipe"  v-for=" item in goodData.goodsSpu.picUrls"  :key="item">
        <img v-lazy="item" class="good-img" :style="{ width: '10rem', height: '10rem' }"  :src="item||'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F017bcb58b4db5fa801219c7719b5ff.jpg%401280w_1l_2o_100sh.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1633080046&t=066f469793e5d076b8c6c09caaa84086'" alt="商品图片"/>
        </van-swipe-item>
    </van-swipe>
        <div class="good-info">
            <div class="title">{{goodData.name ||"占位符"}}</div>
            <div class="good-price">
                <div class="active-price">￥{{goodData.seckillPrice}}</div>
                <div class="orgin-price">￥{{goodData.goodsSku.salesPrice}}</div>
            </div>
            <div class="active-time" v-if="goodData.seckillHall.hallTime - new Date().getHours() === 0">
                <div class="time-title">距离本场结束还剩</div>
                <span class="time">{{activeDetail}}</span>
            </div>
        </div>    
        <div class="good-desc">
           <div class="name"> {{goodData.goodsSpu.name}}</div>
            <div class="good-name van-multi-ellipsis--l2" v-if="goodData.goodsSku.specs.length"> <span v-for=" item in goodData.goodsSku.specs" :key="item.specValueName"> {{item.specValueName}}</span>
        </div>
        <div class="box">
            <div class="sell">已售{{goodData.seckillNum}}</div>
            <div class="good-num">限量{{goodData.limitNum}}</div>
            <div class="rule" @click="ruleOpen()">秒杀规则</div>
         </div> 
      </div>
    
      <div class="rule-info" v-show="isShow">
          <div class="rule-box">
              <div class="rule-title">规则说明 <span class="close" @click="ruleClose()">X</span></div>
              <div class="rule-content">{{goodData.seckillRule}}</div>
          </div>
      </div>    
          <div class="store">
             <div class="store-info">
             <div class="store-logo"><img :src="goodData.shopInfo?goodData.shopInfo.imgUrl:'' " alt="店铺logo" /></div>
             <div class="store-name">{{goodData.shopInfo?goodData.shopInfo.name:''}}</div>
             <div class="gostore">
                  <div class="phoneNumber" @click="goShop(goodData.shopInfo.id)">进入店铺  <img src="../../assets/img/返回.png" alt=""></div>
             </div>
          </div>
      </div>
   
     
    <!-- <div class="store-desc">
        <div class="store-item">
            <span>宝贝描述</span>
            <span class="score">4.9</span>
        </div>
        <div class="store-item">
            <span>卖家服务</span>
            <span class="score">4.9</span>
        </div>
        <div class="store-item">
            <span>物流服务</span>
            <span class="score">4.9</span>
        </div>
    </div>
    <div class="recommend">
        <div class="recommend-item">
            <img  class="recommend-img " src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng7efbfe1145d80c5ae98c1a767ba55d766ca513b65a78a7b634878594e57eaa5e" alt="">
            <div class="recommend-desc">厨房置物架落地多层微波…</div>
            <div class="recommend-price"><span class="symbol">￥</span><span class="momeny">48.20</span> </div>
        </div>
    </div> -->
    <div class="desc">
        <span class="title">商品信息</span>
          <div v-html="goodData.goodsSpu.description"></div>
    </div>
    </div>

    <!-- <div class="footer">
      <div class="kefu" @click="$contact(goodData.imAccount)"><img src="../../assets/kefu2.png" alt="">
        客服
      </div>
        <div class="btns">
            <button type="button" class="btn1" @click="buy(false)">原价购买</button>
            <button type="button"    class="btn2" @click="buy(true)" >立即购买</button>
        </div>
    </div> -->
     <van-share-sheet  v-model="showShare"  :options="options" cancel-text=""  @select="onSelect" class="showshare" /> 
  </div>
</template>

<script>
import { Toast } from 'vant';
import {seckillList} from '../../api/seckillDatil/index'
export default {
  data() {
    return {
      showPage:false,
      width: "",//保存屏幕宽度
      isShow:0,//是否显示拼团规则
       showShare: false,
       id:'',
       timerId:0,
       goodData:{},
       activeDetail:'',
       dd:0,
      data:'',
      options: [
        { name: '微信', icon: 'wechat',index:0 },
          { name: '朋友圈', icon: 'wechat-moments', index:1 },
          { name: 'QQ', icon: 'qq' ,index:2},
       
      ],
    };
  },
  methods: {
    //获取屏幕宽度
     onChange(index) {
     console.log(index);
    },
    getWidth() {
      this.width = document.body.clientWidth + "px";
      console.log(this.width);
      return this.width;
    },

    ruleClose(){
        this.isShow=0
    },
    ruleOpen(){
        this.isShow=1
    },
    goShop(id){
      console.log(id);
      this.$router.push({
        path:'/detailsPage',
        query:{
          id:id
        }
      })
    },
      setRem(){
        let screenWidth = document.documentElement.getBoundingClientRect().width
        console.log(111);
        document.documentElement.style.fontSize= (screenWidth/10) + 'px'
        this.getWidth()
      },
      isOpenShare(){
        this.showShare=true
      },
       getWord() {
          clearInterval(this.timerId);

      
          if (this.goodData.seckillHall.hallTime - new Date().getHours() === 0) {
            this.activeDetail= "本场还剩:" +
              (60 - (new Date().getSeconds() === 0)
                ? 59 - new Date().getMinutes()
                : 60 - new Date().getMinutes()) +
              "分" +
              (60 - new Date().getSeconds() + "秒  结束")

              this.timerId=setInterval(()=>{
                this.activeDetail= "本场还剩:" +
              (60 - (new Date().getSeconds() === 0)
                ? 59 - new Date().getMinutes()
                : 60 - new Date().getMinutes()) +
              "分" +
              (60 - new Date().getSeconds() + "秒  结束")
              if(this.goodData.seckillHall.hallTime - new Date().getHours() !== 0){
                this.getWord()
              }
              },1000)
              return
            }
          if (this.goodData.seckillHall.hallTime < new Date().getHours()) {
             this.activeDetail="本场已结束"
           return 
          } else {
             this.activeDetail="即将开始"
            return 
          }
       },
       buy(type){
         
        if(this.goodData.seckillHall.hallTime - new Date().getHours() !== 0 &&type){
          Toast("未在活动时间")
          return
         }

        
         if(type){
            if((this.goodData.isBuy-0) ){ 
           this.$toast('不可重复购买该活动商品')
           return
         }
           console.log(this.goodData.id,"marketId");
           sessionStorage.setItem('marketId',this.goodData.id)
            this.data={
            type:3,
            price:this.goodData.goodsSku.salesPrice.toFixed(2),
            name:this.goodData.goodsSpu.name,
            subtitle:this.goodData.shareTitle,
            activeprice:this.goodData.seckillPrice.toFixed(2),
            relationId:this.goodData.seckillHall.id,
            marketId:this.goodData.id,
            skuId:this.goodData.goodsSku.id,
            spuId:this.goodData.goodsSpu.id,
            quantity:1,
            paymentWay:2,
            deliveryWay:1,
            img:this.goodData.picUrl,
         }
            
         }else{
            this.data={
            type:'0',
            price:this.goodData.goodsSku.salesPrice.toFixed(2),
            name:this.goodData.goodsSpu.name,
            subtitle:this.goodData.shareTitle,
            activeprice:'',
            relationId:this.goodData.seckillHall.id,
            skuId:this.goodData.goodsSku.id,
            spuId:this.goodData.goodsSpu.id,
            quantity:1,
            paymentWay:2,
            deliveryWay:1,
            img:this.goodData.picUrl,
            marketId:this.goodData.id,
         }
         }


        this.$router.push({
          path:'/activeOrder',
          query:{
           data:JSON.stringify(this.data) 
          }
        })
       },
        onSelect(index){
       
     console.log(index.index)
      this.shareInfo({
      title:this.goodData.name,
      picUrl:this.goodData.picUrl|| "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge075b8c61cca1d61d6fd515ef1b8fe29671548b08ff1d214b42b6e4dded6c95e",
      platformId:index.index,
      openUrl:'',
      content:this.goodData.seckillRule
    }) 
   
  },
  },
  created(){
    
    if(navigator.userAgent.indexOf('万民')==-1){
      this.openApp()
      return
    }

    if(this.$route.query.session){
      
    this.$store.commit('setSession',this.$route.query.session||'684a1dbc-cd92-409d-893b-313797e8cee9' )

    }
  
  seckillList(this.$route.query.id).then(res=>{
    if(res.data.code===0){
      this.goodData=res.data.data
      console.log(this.goodData,"秒杀详情");
      this.showPage=true
      this.getWord()
    }
  }).catch(err=>{
    console.log(err,"eorry");
  })
  },
  mounted(){

    this.setRem()
    window.addEventListener('orientationchange',this.setRem)
    window.addEventListener('resize',this.setRem)
  },
  beforeDestroy(){
     document.documentElement.style.fontSize =  "16px";
  }
}
</script>

<style  lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
.seckillDatil {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
 
  overflow: hidden;
  -webkit-overflow-scrolling:touch;
  overflow: hidden; 
}
.seckillDatil::-webkit-scrollbar{
    display: none;
}
.my-swipe .van-swipe-item {
  height: 10rem;
  width: 10rem;
    text-align: center;
    
  }
.head {
  z-index: 101;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: white;
  padding:.266667rem ;
  border-bottom: 1px solid #ccc;

  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  /* background-color: red; */
}
.head .back {
  width: 0.666667rem;
  height: 0.64rem;
  
 
  margin-right: .16rem;
}
.head .title {
  
 text-align: center;
 
  font-size: .453333rem;
  font-weight: 600;
}
.head .share{
  float: right;
  width: .666667rem;
  height: .666667rem;
}
 /deep/ .showshare {
  z-index: 10000 !important; 
}
.content::-webkit-scrollbar{
    display: none;
}
.content{
 
  position: absolute;
  // padding-top:0.rem ;
  top: 1.193333rem;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  z-index: 100;
  -webkit-overflow-scrolling: touch;
}
.content::-webkit-scrollbar{
  display: none;
}
.good-img{
    float: left;
}
.good-info {
 width: 100%;

background: #E64D42;
padding: .533333rem .426667rem;
float: left;
box-sizing: border-box;
display: flex;
flex-wrap: wrap;
justify-content: space-between;

}
.good-price {
  position: relative;
}
.good-info .title {
 width: 100%;
 /* overflow: hidden;
text-overflow:ellipsis;
white-space: nowrap; */
font-size: .48rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #FFFFFF;
line-height: .666667rem;
margin-bottom: .266667rem;
}
.active-price {
width: 1.44rem;
height: .666667rem;
font-size: .48rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #FFFFFF;
line-height: .666667rem;

}
.orgin-price {
 width: 1.12rem;
height: .533333rem;
font-size: .373333rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: .533333rem;
text-decoration: line-through;
}
.active-time{
  
color: #FFFFFF;
text-align: center;

}
.active-time .time-title{

height: .533333rem;
font-size: .373333rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #FFFFFF;
line-height: .533333rem;
margin-bottom: .133333rem;
}
.active-time .time{
    width: 1.146667rem;
height: .373333rem;
font-size: .266667rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #E64D42;
line-height: 0.3784rem;
  padding: 0 0.1351rem;
height: 0.4324rem;
background: #FFFFFF;
border-radius: 0.2162rem;
}
.good-desc {
   
  background-color: white;
width: 100%;
  padding: 0.4rem 0.266rem 0.466rem;
  float: left;
  font-size: 0.32rem;

  box-sizing: border-box;
}
.good-name{
    width: 9.146rem;
font-size: 0.48rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #333333;
line-height: 0.666rem;
margin-bottom: .266667rem;
}

.box {
  display: flex;
  justify-content: space-between;
  color: #999;
  font-size: 0.32rem;
 
}

.rule {
  color: #1e90ff;
}
.store {
  margin-top: .266667rem;
    padding-top: 0.533rem;
  background-color: white;
  float: left;
  width: 100%;
  padding: 0.266rem;
  font-size: 0.32rem;
box-sizing: border-box;
}
.store-info {
 
  display: flex;
  align-items: center;
  color: #aaa;
 

}
.store-logo {
  width: 0.666rem;
  height: 0.666rem;
  margin-right: 0.26rem;
  display: flex;
  align-items: center;
 
}
.store-name{
    

font-size: 0.373rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #333333;

}
.gostore{
flex: 1;
}
.phoneNumber{
display: flex;
justify-content: flex-end;

}
.phoneNumber img{
margin-left: 0.213rem;
  

}
.store-desc{
    float: left;
    display: flex;
   padding-top: 0.4rem;
   height: 1.066rem;
   background-color: white;
   width: 100%;
}
.store-item{
    flex: 1;
height: 1.12rem;
line-height: 1.12rem;
text-align: center;

font-size: 0.3784rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #999999;
line-height: 0.533rem;
}
.score{
   width: 0.48rem;
height: 0.453rem;
font-size: 0.32rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #DA0B06;
line-height: 0.453rem;
margin-left: 0.133rem;
}
.recommend{
    float: left;
    display: flex;
    background-color: white;
    overflow: auto;
    width: 100%;
    padding-left: 0.426rem;
    box-sizing: border-box;
}
.recommend-item{
    width: 2.4rem;
    margin-right: 0.986rem;
}
.recommend-img{
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.133rem;
    margin-bottom: 0.266rem;
}
.recommend-desc{
   width: 2.4rem;
height: 1.066rem;
font-size: 0.373rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #333333;
line-height: 0.533rem;
}
.recommend-price{
    color: #A80000;
    margin-top: 0.266rem;
    
}
.name{
  font-weight: 600;
  font-size: .48rem;
  margin-bottom: .266667rem;
}
.symbol{

width: 0.24rem;
height: 0.533rem;
font-size: 0.373rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #A80000;
line-height: 0.533rem;
}
.recommend-price .momeny{
  width: 1.306rem;
height: 0.666rem;
font-size: 0.48rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #A80000;
line-height: 0.666rem;
}
.desc {

  float: left;
  text-align: center;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  padding-top: 0.973rem;
  padding-bottom: 3rem;
   margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  
}
.desc .title{
    width: 1.6216rem;
height: 0.5676rem;
font-size: 0.4054rem;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #333333;
line-height: 0.5676rem;
}
.footer{
    z-index: 10000;
    position: fixed;
    width: 100%;
    height:1.493333rem;
    bottom: 0;
    left: 0;
    background-color: white;
    border-top: 1px solid #ddd;
   
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-overflow-scroll:touch;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
}
.kefu{
  display: flex;
  flex-direction: column;
   flex: 1;
   align-items: center;
}
.kefu img{
  width: .666667rem;
  height: .666667rem;
 
}
.btns{
   float: right;
   display: flex;
   justify-content: space-between;
   margin-right: 0.426rem;
   align-items: center;
   height: 100%;
}
.btns *{
    font-size: 0.373rem;
    color: white;
       width: 3.786rem;
    height: 0.96rem;
    background-position: center top;
    background-size: 100% 100%;
    text-align: center;
    
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn1{
    background-image: url('../../assets/img/btnorange.png');
 
}
.btn2{
    background-image: url('../../assets/img/矩形.png');
}
.rule-info{
    z-index: 200;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
}
.rule-box{
    background-color: white;
    width: 80%;
    
    border-radius: 0.133rem;
    overflow: hidden;
}
.rule-title{
    text-align: center;
    padding: 0.2703rem 0;
}
.rule-title span{
    float: right;
    margin-right: 0.533rem;
    color: #FF7200FF;
}
.rule-content{
    height: 100%;
    width: 100%;
    padding-left: 0.426rem;
    background-color: #ccc;
    box-sizing: border-box;
    padding-top: 0.266rem;
    padding-bottom: .266667rem;
    padding: 0.26rem 10%;
    white-space: pre-wrap;
}


</style>